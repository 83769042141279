import React from "react";
import { Drawer, Typography, Box } from "@mui/material";
import { useAuth } from "../AuthProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import { useTask } from "../TaskContext"; // Import useTask hook
import ChangeLog from "../components/ChangeLog";

const DrawerComponent = ({ drawerOpen, setDrawerOpen, tokenInfo }) => {
  const { userName } = useAuth();
  const { tokenData } = useTokenTracker();
  const { usageTask, taskUsage } = useTask();

  // Mapping of endpoint names to display names
  const imageModelNames = {
    "fal-ai/stable-diffusion-v3-medium": "Stable Diffusion v3 Medium",
    "fal-ai/fast-sdxl": "Fast SDXL",
    "fal-ai/playground-v25": "Playground v2.5",
    "fal-ai/flux-pro/v1.1-ultra": "FLUX.1.1 [pro] ultra",
    "fal-ai/flux/schnell": "FLUX.1 [schnell]",
    "fal-ai/flux/dev": "FLUX.1 [dev]",
    "fal-ai/flux-realism": "FLUX Realism LoRA",
  };

  const extractLLMInfo = (tokenInfo) => {
    if (!tokenInfo) return null;

    const lines = tokenInfo.split("\n");
    let info = {
      model: "",
      promptTokens: 0,
      completionTokens: 0,
      totalTokens: 0,
      cost: 0,
      sessionTotalTokens: 0,
      sessionTotalCost: 0,
      modelsUsed: "",
    };

    for (const line of lines) {
      if (line.startsWith("Model:")) info.model = line.split(":")[1].trim();
      if (line.startsWith("Prompt tokens:"))
        info.promptTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Completion tokens:"))
        info.completionTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Total tokens:"))
        info.totalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Cost (PHP):"))
        info.cost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Session Total Tokens:"))
        info.sessionTotalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Session Total Cost (PHP):"))
        info.sessionTotalCost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Models Used:"))
        info.modelsUsed = line.split(":")[1].trim();
    }

    return info;
  };

  const llmInfo = extractLLMInfo(tokenInfo);

  const formatTaskUsage = (taskUsageObj) => {
    if (!taskUsageObj || Object.keys(taskUsageObj).length === 0) {
      return "None";
    }
    return Object.entries(taskUsageObj)
      .map(([task, count]) => `${task} (${count}x)`)
      .join(", ");
  };

  const hasLLMActivity = llmInfo && llmInfo.totalTokens > 0;
  const hasImageActivity = tokenData.totalImageCostPHP > 0;
  const hasAudioActivity = tokenData.totalAudioCostPHP > 0;
  const audioModelUsed = tokenData.modelsUsed["Whisper v3"] || 0;
  const hasAnyActivity = hasLLMActivity || hasImageActivity || hasAudioActivity;

  const formatOperations = (operations) => {
    return Object.entries(operations)
      .map(([operation, count]) => `${operation} (${count}x)`)
      .join(", ");
  };

  const formatImageModelsUsed = (modelsUsed, imageOperations) => {
    const createImageCount = imageOperations["Create Image"] || 0;
    const transformImageCount = imageOperations["Transform Image"] || 0;

    let result = [];

    if (createImageCount > 0) {
      Object.entries(modelsUsed)
        .filter(([model]) => model.startsWith("fal-ai/"))
        .forEach(([model, count]) => {
          const displayName = imageModelNames[model] || model;
          result.push(`${displayName} (${count} images)`);
        });
    }

    if (transformImageCount > 0) {
      result.push(`SD 1.5 Depth ControlNet (${transformImageCount} images)`);
    }

    return result.join(", ");
  };

  const costInfo = hasAnyActivity
    ? `
LLM Usage:
${
  hasLLMActivity
    ? `Task: ${usageTask || "Unknown"}
Model: ${llmInfo.model || "Unknown"}
Prompt tokens: ${llmInfo.promptTokens || 0}
Completion tokens: ${llmInfo.completionTokens || 0}
Total Tokens: ${llmInfo.totalTokens || 0}
Cost (PHP): ${(llmInfo.cost || 0).toFixed(6)}
Session Total Tokens: ${llmInfo.sessionTotalTokens || 0}
Total LLM Cost (PHP): ${(llmInfo.sessionTotalCost || 0).toFixed(2)}
Tasks: ${formatTaskUsage(taskUsage)}
Models Used: ${llmInfo.modelsUsed || "Unknown"}`
    : "No LLM usage yet."
}

Image Operations:
${
  hasImageActivity
    ? `Latest Operation: ${
        tokenData.lastImageOperation
      } Inference Time: ${tokenData.lastImageInferenceTime.toFixed(
        3
      )}s Cost per Image (PHP): ${tokenData.latestImageCostPHP.toFixed(2)} ${
        tokenData.lastImageOperation === "Create Image"
          ? `Number of Images: ${tokenData.lastImageNumImages}`
          : ""
      } Total Cost (PHP): ${(
        tokenData.latestImageCostPHP *
        (tokenData.lastImageOperation === "Create Image"
          ? tokenData.lastImageNumImages
          : 1)
      ).toFixed(
        2
      )} Overall Image Cost (PHP): ${tokenData.totalImageCostPHP.toFixed(
        2
      )} Operations: ${formatOperations(
        tokenData.imageOperations
      )} Models Used: ${formatImageModelsUsed(
        tokenData.modelsUsed,
        tokenData.imageOperations
      )}`
    : "No image operations yet."
}

Audio Operations:
${
  hasAudioActivity
    ? `Latest Operation: ${tokenData.lastAudioOperation}
Latest Audio Cost (PHP): ${tokenData.latestAudioCostPHP.toFixed(2)}
Total Audio Cost (PHP): ${tokenData.totalAudioCostPHP.toFixed(2)}
Operations: ${formatOperations(tokenData.audioOperations)}
Model Used: Whisper v3 (${audioModelUsed}x)`
    : "No audio operations yet."
}

Session Overall Usage:
Total Cost (PHP): ${(
        (llmInfo?.sessionTotalCost || 0) +
        tokenData.totalImageCostPHP +
        tokenData.totalAudioCostPHP
      ).toFixed(2)}
`.trim()
    : "No usage data yet.";

    return (
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: 280,
          "& .MuiDrawer-paper": {
            width: "280px",
            backgroundColor: "#282c34",
            color: "#f0f0f0",
            padding: "10px",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            height: "92%",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#f0f0f0", marginBottom: "20px" }}
          >
            Hi, {userName}!
          </Typography>
  
          <Typography
            variant="subtitle1"
            sx={{
              color: "#f0f0f0",
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Changelog
          </Typography>
          
          <ChangeLog />
  
          {/* Token Count & Cost Section */}
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Usage Information
          </Typography>
          <textarea
            value={costInfo}
            readOnly
            className="drawer-textarea"
            style={{
              backgroundColor: "#282c34",
              color: "#f0f0f0",
              padding: "10px",
              height: "30vh",
              overflowY: "auto",
              fontSize: "12px",
              border: "1px solid #444",
              borderRadius: "4px",
              resize: "none",
            }}
          />
        </Box>
      </Drawer>
    );
  };
  
  export default DrawerComponent;