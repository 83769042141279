import React from "react";
import { Box, Switch, FormControlLabel, Typography } from '@mui/material';

const TranscriptionDisplay = ({ 
  transcriptionData, 
  readOnly = true,
  showTimestamps,
  onToggleTimestamps
}) => {
  const formatTime = (seconds) => {
    if (!seconds && seconds !== 0) return '';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${mins}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
  };

  const formatTranscription = () => {
    if (typeof transcriptionData === 'string') {
      return transcriptionData;
    }

    if (!transcriptionData) return '';
    
    if (!showTimestamps || !transcriptionData.chunks || transcriptionData.chunks.length === 0) {
      return transcriptionData.full_text || '';
    }

    return transcriptionData.chunks
      .map(chunk => {
        if (!chunk.timestamp || chunk.timestamp.length !== 2) {
          return chunk.text;
        }
        const startTime = formatTime(chunk.timestamp[0]);
        const endTime = formatTime(chunk.timestamp[1]);
        return `[${startTime} - ${endTime}]\n${chunk.text}\n`;
      })
      .join('\n');
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginLeft: '10px'
      }}
    >
      <textarea
        value={formatTranscription()}
        readOnly={readOnly}
        className="transcript-container"
        style={{
          width: '91%',
          minHeight: '200px',
          padding: '16px',
          backgroundColor: '#282c34',
          color: '#e0e0e0',
          border: '1px solid #404040',
          borderRadius: '8px',
          fontFamily: 'Source Code Pro, monospace',
          fontSize: '14px',
          lineHeight: '1.6',
          whiteSpace: 'pre-wrap',
          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.2)',
          resize: 'vertical',
          marginBottom: '15px',
          marginLeft: '0px'
        }}
      />
    </Box>
  );
};

export const TimestampToggle = ({ showTimestamps, onToggleTimestamps }) => (
  <FormControlLabel
    control={
      <Switch
        checked={showTimestamps}
        onChange={onToggleTimestamps}
        color="primary"
        size="medium"
      />
    }
    label={
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'white',
          fontSize: '14px',
          marginRight: '8px'
        }}
      >
        Show Timestamps
      </Typography>
    }
    sx={{
      margin: 0,
      '.MuiFormControlLabel-label': {
        fontSize: '12px'
      }
    }}
  />
);

export default TranscriptionDisplay;