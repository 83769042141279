import { makeApiCall } from "../api/apiCalls";

const prepareTask = (
  currentTask,
  taskType,
  history,
  prompt,
  setIsNewChatSession,
  setCurrentTask
) => {
  if (currentTask !== taskType) {
    setIsNewChatSession(true);
  }
  setCurrentTask(taskType);

  return history ? history + "\n" + prompt : prompt;
};

const handleTaskRequest = async (
  taskType,
  endpoint,
  llmChoice,
  languageChoice,
  summaryStyleChoice,
  setTaskStates,
  taskStateValues,
  exchangeRate
) => {
  const {
    currentTask,
    prompt,
    brainstormHistory,
    chatHistory,
    sessionData,
    setSessionData,
    updateTokenData,
  } = taskStateValues;

  let combinedPrompt = prompt;

  // Use prepareTask only for "Brainstorm" and "Freeform Chat"
  if (taskType === "Brainstorm" || taskType === "Freeform Chat") {
    combinedPrompt = prepareTask(
      currentTask,
      taskType,
      taskType === "Brainstorm" ? brainstormHistory : chatHistory,
      prompt,
      setTaskStates.setIsNewChatSession,
      setTaskStates.setCurrentTask
    );
  }

  // Set the default temperature and adjust based on the task type
  let temperature = 0.7;
  if (taskType === "Summary" || taskType === "Translation") {
    temperature = 0.2;
  }

  // Set max_tokens based on llmChoice
  let maxTokens = llmChoice === "Groq Llama-3 70B 8K" ? 2048 : 4096;

  let requestBody = {
    prompt: combinedPrompt,
    llm_model_choice: llmChoice,
    task_choice: taskType, // This ensures the backend knows the type of task
    language_choice: languageChoice,
    max_tokens: maxTokens,
    temperature: temperature,
    session_data: sessionData,
    usd_to_php_rate: exchangeRate.rate, // Include exchange rate
    rate_timestamp: exchangeRate.timestamp,
  };

  // Conditionally add summary_style_choice for summary tasks
  if (taskType === "Summary" && summaryStyleChoice) {
    requestBody.summary_style_choice = summaryStyleChoice;
  }

  await makeApiCall(
    endpoint, // This should be your unified endpoint, e.g., '/generate/'
    requestBody,
    taskType,
    setTaskStates.setBrainstormHistory,
    setTaskStates.setChatHistory,
    setTaskStates.setNonChatDraftHistory,
    setTaskStates.setIsNewChatSession,
    setTaskStates.setGeneratedText,
    setTaskStates.setTokenInfo,
    setTaskStates.isNewChatSession,
    prompt,
    brainstormHistory,
    chatHistory,
    setSessionData,
    updateTokenData
  );
};

export { prepareTask, handleTaskRequest };
