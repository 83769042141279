import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ImagePromptBox from "../components/ImagePromptBox";

import { useSessionData } from "../SessionDataProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useTokenTracker } from "../TokenTrackerContext";

import { generateImage, uploadImage, transformImage } from "../api/apiCalls";
import { getApiBaseUrl } from "../api/apiClient";

const CreateImageTab = ({
  model,
  setModel,
  prompt,
  setPrompt,
  imageUrl,
  setImageUrl,
  isGenerating,
  setIsGenerating,
  enhancePrompt,
  setEnhancePrompt,
  uploadedImageUrl,
  setUploadedImageUrl,
  transformedImageUrl,
  setTransformedImageUrl,
  upscaleImage,
  uploadedFile,
  setUploadedFile,
  upscaledImageUrl,
  setUpscaledImageUrl,
  task,
  setTask,
}) => {
  const { sessionData, setSessionData } = useSessionData();
  const { exchangeRate } = useExchangeRate();
  const { updateTokenData } = useTokenTracker();

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleTaskChange = (event) => {
    setTask(event.target.value);
    // Reset images when switching tasks
    if (uploadedImageUrl && task === "upscale") {
      URL.revokeObjectURL(uploadedImageUrl);
    }
    setUploadedImageUrl(null);
    setTransformedImageUrl(null);
    setUpscaledImageUrl(null);
    setImageUrl(null);
    setUploadedFile(null);
  };

  const [numImages, setNumImages] = useState(1);

  const calculateImageCost = (inferenceTime, numImages = 1) => {
    const costPerSecond = 0.00111;
    const totalCostUSD = inferenceTime * costPerSecond;
    const totalCostPHP = totalCostUSD * exchangeRate.rate;
    const costPerImagePHP = totalCostPHP / numImages;

    return {
      totalCostUSD,
      totalCostPHP,
      costPerImagePHP,
    };
  };

  const handleGenerateImage = async () => {
    setIsGenerating(true);
    try {
      const response = await generateImage(
        model,
        prompt,
        enhancePrompt,
        numImages,
        sessionData,
        exchangeRate.rate
      );
      if (response && response.images && response.images.length > 0) {
        setImageUrl(response.images.map((img) => img.url));
        if (enhancePrompt && response.final_prompt) {
          setPrompt(`Enhanced Prompt: ${response.final_prompt}`);
        }

        if (response.updated_session_data) {
          setSessionData(response.updated_session_data);
        }
        if (response.cost_info) {
          const { totalCostPHP, costPerImagePHP } = calculateImageCost(
            response.cost_info.inference_time,
            numImages
          );

          updateTokenData({
            imageCostPHP: totalCostPHP,
            latestImageCostPHP: costPerImagePHP,
            modelName: model,
            numImages: numImages,
            inferenceTime: response.cost_info.inference_time,
            operation: "Create Image",
          });
        }
      }
    } catch (error) {
      console.error("Failed to generate images:", error);
      alert("Error generating images: " + error.message);
    }
    setIsGenerating(false);
  };

  const handleUploadImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setIsGenerating(true);
        if (task === "transform") {
          // Existing FAL upload logic
          const response = await uploadImage(file);
          setUploadedImageUrl(response.fal_url);
        } else if (task === "upscale") {
          // Just store the file for upscaling
          setUploadedFile(file);
          // Create object URL for preview
          const objectUrl = URL.createObjectURL(file);
          setUploadedImageUrl(objectUrl);
        }
      } catch (error) {
        console.error("Failed to upload image:", error);
        alert("Error uploading image: " + error.message);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  const handleTransformImage = async () => {
    if (!uploadedImageUrl) {
      alert("Please upload an image first.");
      return;
    }
    setIsGenerating(true);
    try {
      const response = await transformImage(
        uploadedImageUrl,
        prompt,
        sessionData,
        exchangeRate.rate
      );
      if (response && response.images && response.images.length > 0) {
        setTransformedImageUrl(response.images[0].url);

        setSessionData(response.updated_session_data);
        if (response.cost_info) {
          const { totalCostPHP, costPerImagePHP } = calculateImageCost(
            response.cost_info.inference_time
          );

          updateTokenData({
            imageCostPHP: totalCostPHP,
            latestImageCostPHP: costPerImagePHP,
            modelName: "Image Transformation",
            numImages: 1,
            inferenceTime: response.cost_info.inference_time,
            operation: "Transform Image",
          });
        }
      } else {
        alert("Failed to transform image. No image URL returned.");
      }
    } catch (error) {
      console.error("Failed to transform image:", error);
      alert("Error transforming image: " + error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleUpscaleImage = async () => {
    if (!uploadedFile) {
      alert("Please upload an image first.");
      return;
    }
    setIsGenerating(true);
    try {
      const response = await upscaleImage(
        uploadedFile,
        sessionData,
        exchangeRate.rate
      );

      console.log("Full response:", response);

      if (response.upscaled_image_path) {
        const baseUrl = getApiBaseUrl();
        // Replace backslashes with forward slashes
        const cleanPath = response.upscaled_image_path.replace(/\\/g, "/");
        const upscaledUrl = `${baseUrl}${cleanPath}`;
        console.log("Constructed URL:", upscaledUrl);

        setUpscaledImageUrl(upscaledUrl);

        if (response.updated_session_data) {
          setSessionData(response.updated_session_data);
        }

        if (response.cost_info) {
          updateTokenData({
            imageCostPHP: response.cost_info.cost_php,
            latestImageCostPHP: response.cost_info.cost_php,
            modelName: "Recraft Upscaler",
            numImages: 1,
            inferenceTime: response.cost_info.inference_time,
            operation: "Upscale Image",
          });
        }
      }
    } catch (error) {
      console.error("Failed to upscale image:", error);
      alert("Error upscaling image: " + error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Grid container spacing={2} className="content-container">
      <Grid item xs={12} className="main-content-header">
        <p>Create or transform an image using AI.</p>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item>
            <FormControl variant="filled" sx={{ minWidth: 220 }}>
              <InputLabel id="task-select-label" sx={{ color: "white" }}>
                Select Task
              </InputLabel>
              <Select
                style={{
                  backgroundColor: "#444",
                  color: "darkgrey",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                labelId="task-select-label"
                value={task}
                onChange={handleTaskChange}
                label="Select Task"
              >
                <MenuItem value="create">
                  Create an image from a text prompt
                </MenuItem>
                <MenuItem value="transform">
                  Transform an image thru a text prompt
                </MenuItem>
                <MenuItem value="upscale">Upscale an image</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {task === "create" && (
            <Grid item>
              <FormControl variant="filled" sx={{ minWidth: 220 }}>
                <InputLabel id="model-select-label" sx={{ color: "white" }}>
                  Select Model
                </InputLabel>
                <Tooltip
                  title="Try different models for generating images. Default is fine for most use cases. Choose FLUX models for images with short text element."
                  arrow
                  placement="top"
                >
                  <Select
                    style={{
                      backgroundColor: "#444",
                      color: "darkgrey",
                      fontSize: "14px",
                      borderRadius: "4px",
                    }}
                    labelId="model-select-label"
                    value={model}
                    onChange={handleModelChange}
                    label="Select Model"
                  >
                    <MenuItem value="fal-ai/stable-diffusion-v3-medium">
                      Stable Diffusion v3 Medium
                    </MenuItem>
                    <MenuItem value="fal-ai/fast-sdxl">Fast SDXL</MenuItem>
                    <MenuItem value="fal-ai/playground-v25">
                      Playground v2.5
                    </MenuItem>
                    <MenuItem value="fal-ai/flux/schnell">
                      FLUX.1 [schnell]
                    </MenuItem>
                    <MenuItem value="fal-ai/flux/dev">FLUX.1 [dev]</MenuItem>
                    <MenuItem value="fal-ai/flux-pro/v1.1-ultra">
                      FLUX.1.1 [pro] ultra
                    </MenuItem>
                    <MenuItem value="fal-ai/flux-realism">
                      FLUX Realism LoRA
                    </MenuItem>
                  </Select>
                </Tooltip>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      {task === "upscale" && (
      <Grid
      item
      xs={12}
      sx={{ marginTop: "-10px", marginBottom: "-10px", marginLeft: "0px", color: "grey" }}
      className="main-content-subheader"
    >
      <p>
      Upscale low resolution image to higher resolution while
            maintaing image quality. Right-click on image to save. Supported file types: PNG, JPG, WEBP. Max. file size
            10MB.
      </p>
    </Grid>
      )}

      {task !== "upscale" && (
        <Grid item xs={12}>
          <ImagePromptBox
            label="Your Prompt"
            isImagePromptBox={true}
            value={prompt}
            className="image-prompt-container"
            onChange={(e) => setPrompt(e.target.value)}
            placeholder={
              task === "create"
                ? "Describe the image you want: subject matter, style (photorealistic, cartoon, painting, etc.). Optionally, turn on 'Enhance Prompt' to let the AI enhance its effectiveness. Click GENERATE button, image will appear below. Right-click to save."
                : "Upload your image file (accepted file types: jpg, jpeg, png, webp). Describe how you want to transform it and click 'Transform'. The AI will generate a new image based on the outlines of your uploaded image. Right-click to save."
            }
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" gap={2}>
          {task === "create" ? (
            <>
              <Button
                style={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  marginTop: "-10px",
                }}
                variant="contained"
                onClick={handleGenerateImage}
                disabled={isGenerating}
              >
                {isGenerating ? "Generating..." : "Generate"}
              </Button>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="task-choice-label" sx={{ color: "white" }}>
                  Number of Images
                </InputLabel>
                <Select
                  style={{
                    backgroundColor: "#444",
                    color: "darkgrey",
                    fontSize: "12px",
                    borderRadius: "2px",
                    paddingLeft: "10px",
                    marginBottom: "25px",
                  }}
                  labelId="num-images-label"
                  value={numImages}
                  onChange={(e) => setNumImages(e.target.value)}
                  label="Number of Images"
                >
                  {[1, 2, 3, 4].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                style={{ marginBottom: "10px" }}
                control={
                  <Switch
                    checked={enhancePrompt}
                    onChange={(e) => setEnhancePrompt(e.target.checked)}
                    name="enhancePrompt"
                    color="primary"
                  />
                }
                label="Enhance Prompt"
                labelPlacement="end"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
              />
            </>
          ) : (
            <>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleUploadImage}
              />
              <label htmlFor="raised-button-file">
                <Button
                  style={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    marginTop: "3px",
                  }}
                  variant="contained"
                  component="span"
                >
                  Upload Image
                </Button>
              </label>
              {task === "transform" ? (
                <Button
                  style={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    marginTop: "3px",
                  }}
                  variant="contained"
                  onClick={handleTransformImage}
                  disabled={isGenerating || !uploadedImageUrl}
                >
                  {isGenerating ? "Transforming..." : "Transform"}
                </Button>
              ) : (
                <Button
                  style={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    marginTop: "3px",
                  }}
                  variant="contained"
                  onClick={handleUpscaleImage}
                  disabled={isGenerating || !uploadedFile}
                >
                  {isGenerating ? "Upscaling..." : "Upscale"}
                </Button>
              )}
            </>
          )}
        </Box>
      </Grid>

      <Grid container item xs={12} spacing={2}>
        {task === "create" ? (
          <>
            {Array.from({ length: numImages }).map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                {isGenerating ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={512}
                    animation="wave"
                  />
                ) : imageUrl && imageUrl[index] ? (
                  <img
                    src={imageUrl[index]}
                    alt={`Generated ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                ) : null}
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              {isGenerating && !uploadedImageUrl ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={512}
                  animation="wave"
                />
              ) : uploadedImageUrl ? (
                <img
                  src={uploadedImageUrl}
                  alt="Uploaded"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              {isGenerating && uploadedImageUrl ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={512}
                  animation="wave"
                />
              ) : task === "transform" ? (
                transformedImageUrl && (
                  <img
                    src={transformedImageUrl}
                    alt="Transformed"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                )
              ) : (
                upscaledImageUrl && (
                  <img
                    src={upscaledImageUrl}
                    alt="Upscaled"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                )
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateImageTab;
