import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../AuthProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useSessionData } from "../SessionDataProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import LoginForm from "../components/LoginForm";
import AdminDashboard from "../components/AdminDashboard";
import Tabs, { Tab } from "../components/Tabs";
import AppBarComponent from "../components/AppBarComponent";
import DrawerComponent from "../components/DrawerComponent";
import WriteTab from "../components/WriteTab";
import UnifiedPdfSummaryTab from "../components/UnifiedPdfSummaryTab";
import TakeNotesTab from "../components/TakeNotesTab";
import CreateImageTab from "../components/CreateImageTab";
import { upscaleImage } from "../api/apiCalls";
import { scrapeUrls } from '../api/apiCalls';

import TranscribeAudioTab from "../components/TranscribeAudioTab";
import "./HomePage.css";
import { handleTaskRequest } from "../utils/taskUtils";
import { useTask } from "../TaskContext";

// Material-UI Imports
import Box from "@mui/material/Box";

const HomePage = () => {
  const { isAuthenticated, userRole } = useAuth();
  const [showDashboard, setShowDashboard] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatedText, setGeneratedText] = useState("");
  const { exchangeRate } = useExchangeRate(); // Access exchange rate info
  const [scratchPadText, setScratchPadText] = useState("");
  const [chatHistory, setChatHistory] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [nonChatDraftHistory, setNonChatDraftHistory] = useState("");
  const [draftHistory, setDraftHistory] = useState("");
  const [brainstormHistory, setBrainstormHistory] = useState("");
  const [isNewChatSession, setIsNewChatSession] = useState(true);
  const [taskChoice, setTaskChoice] = useState(""); // Default option
  const [currentTask, setCurrentTask] = useState("");
  const [llmChoice, setLlmChoice] = useState(""); // State for the LLM choice
  const [languageChoice, setLanguageChoice] = useState("Tagalog"); // State for the language choice
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const { updateTokenData } = useTokenTracker();
  const [tokenInfo, setTokenInfo] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [summaryStyleChoice, setSummaryStyleChoice] = useState("Bullets");
  const [fileUrl, setFileUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.6);
  const [outputHistory, setOutputHistory] = useState("");
  const [notesFileUrl, setNotesFileUrl] = useState("");
  const [notesNumPages, setNotesNumPages] = useState(0);
  const [notesPageNumber, setNotesPageNumber] = useState(1);
  const [notesScale, setNotesScale] = useState(1);
  const [notesMessages, setNotesMessages] = useState([]);
  const { sessionData, setSessionData } = useSessionData();
  const [userSelectedLLM, setUserSelectedLLM] = useState(false);
  const [imageModel, setImageModel] = useState("fal-ai/flux/schnell");
  const [imagePrompt, setImagePrompt] = useState("");
  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [enhancePrompt, setEnhancePrompt] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [transformedImageUrl, setTransformedImageUrl] = useState("");
  const [imageTask, setImageTask] = useState("create");
  const [audioTask, setAudioTask] = useState("transcribe");
  const [audioLanguage, setAudioLanguage] = useState("tl");
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcriptionResult, setTranscriptionResult] = useState("");
  const [transcriptionResponse, setTranscriptionResponse] = useState(null);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  //const handleToggleForms = () => setShowLoginForm(!showLoginForm); // Function to toggle forms
  const { setUsageTask, updateTaskUsage } = useTask();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [upscaledImageUrl, setUpscaledImageUrl] = useState(null);
  const cleanupRef = React.useRef({ url: null, currentTask: null });
  const [summaryMode, setSummaryMode] = useState("simple");
  const [fetchError, setFetchError] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [pressReleaseLanguage, setPressReleaseLanguage] = useState("English & Filipino");

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDashboard = () => {
    if (userRole === "admin") {
      setShowDashboard(!showDashboard);
    }
  };

  const handleLlmChoiceChange = useCallback(
    (e) => {
      setUserSelectedLLM(true);
      setLlmChoice(e.target.value);
    },
    [setUserSelectedLLM, setLlmChoice]
  ); // Dependencies necessary for useCallback

  const handleTaskChoiceChange = (e) => {
    setTaskChoice(e.target.value);
  };

  useEffect(() => {
    // Automatically set LLM choice based on the selected task
    switch (taskChoice) {
      case "Freeform":
      case "House Bill":
      case "House Resolution":
      case "Explanatory Note":
      case "Brainstorm":
      case "Press Release":
      case "Press Statement":
      case "Makabayan Press Statement":
        setLlmChoice("Claude 3.5 Sonnet");
        break;
      case "Summary":
        setLlmChoice("Claude 3.5 Haiku");
        break;        
      case "Translation":
        setLlmChoice("GPT-4o");
        break;
      case "Chat":
        setLlmChoice("GPT-4o (latest)");
        break;
      // Add more cases here for different tasks as needed
      default:
        // Optionally set a default LLM choice for any task not explicitly handled
        break;
    }
  }, [taskChoice]);

  useEffect(() => {
    // Automatically set LLM choice based on the selected summary style
    switch (summaryStyleChoice) {
      case "Bullets":
        setLlmChoice("Claude 3.5 Haiku");
        break;
      case "Powerpoint":
        setLlmChoice("Claude 3.5 Sonnet");
        break;
      case "Notes & Insights":
        setLlmChoice("Claude 3.5 Sonnet");
        break;
      case "Narrative":
        setLlmChoice("GPT-3.5 Turbo");
        break;
      case "Q & A Explainer":
        setLlmChoice("Claude 3.5 Sonnet");
        break;
      default:
        setLlmChoice("Claude 3.5 Haiku"); // Default to Haiku for simpler summaries
        break;
    }
  }, [summaryStyleChoice]);

  const handleSummaryStyleChoiceChange = (e) => {
    setSummaryStyleChoice(e.target.value); // Update the state with the new summary style choice
  };

  const handleLanguageChoiceChange = (e) => {
    setLanguageChoice(e.target.value); // Update the state with the new language choice
  };

  const handleTokenCountUpdate = (tokenCount) => {
    setTokenInfo(tokenCount);
  };

const handleGenerateClick = async () => {
  setIsGenerating(true);

  let modifiedPrompt = prompt;
  
  // Modify prompt based on language selection for relevant tasks
  if (["Press Release", "Press Statement", "Makabayan Press Statement"].includes(taskChoice)) {
    if (pressReleaseLanguage === "English & Filipino") {
      modifiedPrompt = `${prompt}\n\nInclude quotes in English and Filipino.`;
    } else if (pressReleaseLanguage === "Filipino") {
      modifiedPrompt = `${prompt}\n\nWrite statement in Filipino.`;
    }
  }

  let usageTaskName = taskChoice;
  if (taskChoice === "Summary") {
    usageTaskName += `-${summaryStyleChoice}`;
  } else if (taskChoice === "Translation") {
    usageTaskName += `-${languageChoice}`;
  }
  setUsageTask(usageTaskName);
  updateTaskUsage(usageTaskName);

  const taskStateValues = {
    currentTask,
    prompt: modifiedPrompt, // Use the modified prompt here
    brainstormHistory,
    chatHistory,
    sessionData,
    setSessionData,
    updateTokenData,
    summaryStyleChoice,
  };

  const setTaskStates = {
    setCurrentTask,
    setIsNewChatSession,
    setBrainstormHistory,
    setChatHistory,
    setNonChatDraftHistory,
    setGeneratedText,
    setTokenInfo,
    isNewChatSession,
  };

  const unifiedEndpoint = "/generate/";

  await handleTaskRequest(
    taskChoice,
    unifiedEndpoint,
    llmChoice,
    languageChoice,
    summaryStyleChoice,
    setTaskStates,
    taskStateValues,
    exchangeRate
  );

  setIsGenerating(false);
};

  useEffect(() => {
    if (taskChoice === "Brainstorm") {
      // Set Draft History to the Brainstorm history
      setDraftHistory(brainstormHistory);
    } else if (taskChoice === "Chat") {
      // Set Draft History to the Chat history
      setDraftHistory(chatHistory);
    } else {
      // For other tasks, use the non-chat draft history
      setDraftHistory(nonChatDraftHistory);
    }
  }, [taskChoice, brainstormHistory, chatHistory, nonChatDraftHistory]);

// Add this new function without modifying existing handleGenerateClick
const handleFetchText = async (prompt) => {
  if (!prompt.trim()) return;
  
  setIsFetching(true);
  try {
    const { source_texts, error_messages } = await scrapeUrls(prompt);
    
    setSourceText(source_texts);
    
    if (error_messages?.length > 0) {
      console.warn('Scraping errors:', error_messages);
      setFetchError(error_messages.join('\n'));
    }
  } catch (error) {
    console.error('Error fetching text:', error);
    setFetchError(error.message || 'Error fetching text');
  } finally {
    setIsFetching(false);
  }
};

  React.useEffect(() => {
    // Update ref when these values change
    cleanupRef.current = { url: uploadedImageUrl, currentTask: imageTask }; // You'll need to lift up 'task' state too

    return () => {
      const { url, currentTask } = cleanupRef.current;
      if (
        url &&
        currentTask === "upscale" &&
        !document.body.contains(document.activeElement)
      ) {
        URL.revokeObjectURL(url);
      }
    };
  }, [uploadedImageUrl, imageTask]); // Include task in dependencies

  if (isAuthenticated) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBarComponent
          toggleDrawer={toggleDrawer}
          toggleDashboard={toggleDashboard}
          showHomePage={() => setShowDashboard(false)}
          tokenInfo={tokenInfo}
        />
        <DrawerComponent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          toggleDrawer={toggleDrawer}
          tokenInfo={tokenInfo}
        />
        {showDashboard ? (
          // Render AdminDashboard if showDashboard is true
          <AdminDashboard />
        ) : (
          // Otherwise, render the homepage content
          <div className="homepage-container">
            <Tabs>
              <Tab label="Write">
                <WriteTab
                  llmChoice={llmChoice}
                  handleLlmChoiceChange={handleLlmChoiceChange}
                  userSelectedLLM={userSelectedLLM}
                  taskChoice={taskChoice}
                  handleTaskChoiceChange={handleTaskChoiceChange}
                  languageChoice={languageChoice}
                  summaryStyleChoice={summaryStyleChoice}
                  handleSummaryStyleChoiceChange={
                    handleSummaryStyleChoiceChange
                  }
                  handleLanguageChoiceChange={handleLanguageChoiceChange}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  sourceText={sourceText}
                  setSourceText={setSourceText}
                  handleFetchText={handleFetchText}
                  fetchError={fetchError}
                  isFetching={isFetching}
                  onFetchText={handleFetchText}                
                  handleGenerateClick={handleGenerateClick}
                  isGenerating={isGenerating}
                  generatedText={generatedText}
                  setGeneratedText={setGeneratedText}
                  scratchPadText={scratchPadText}
                  setScratchPadText={setScratchPadText}
                  draftHistory={draftHistory}
                  pressReleaseLanguage={pressReleaseLanguage}
                  setPressReleaseLanguage={setPressReleaseLanguage}
                  saveToFile
                />
              </Tab>
              <Tab label="Summarize PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>Summarize a long PDF.</p>
                </div>
                <UnifiedPdfSummaryTab
                  outputHistory={outputHistory}
                  setOutputHistory={setOutputHistory}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  onTokenCountUpdate={handleTokenCountUpdate}
                  scale={scale}
                  setScale={setScale}
                  summaryMode={summaryMode}
                  setSummaryMode={setSummaryMode}
                />
              </Tab>

              <Tab label="Chat with PDF">
                <div className="content-container">
                  <p style={{ fontWeight: "bold" }}>
                    Ask questions about the contents of a PDF document.
                  </p>
                </div>
                <TakeNotesTab
                  fileUrl={notesFileUrl}
                  setFileUrl={setNotesFileUrl}
                  numPages={notesNumPages}
                  setNumPages={setNotesNumPages}
                  pageNumber={notesPageNumber}
                  setPageNumber={setNotesPageNumber}
                  scale={notesScale}
                  setScale={setNotesScale}
                  messages={notesMessages}
                  setMessages={setNotesMessages}
                  setTokenInfo={setTokenInfo}
                />
              </Tab>
              <Tab label="Create Image">
                <CreateImageTab
                  model={imageModel}
                  setModel={setImageModel}
                  prompt={imagePrompt}
                  setPrompt={setImagePrompt}
                  imageUrl={generatedImageUrl}
                  setImageUrl={setGeneratedImageUrl}
                  upscaleImage={upscaleImage}
                  isGenerating={isGeneratingImage}
                  setIsGenerating={setIsGeneratingImage}
                  enhancePrompt={enhancePrompt}
                  setEnhancePrompt={setEnhancePrompt}
                  uploadedImageUrl={uploadedImageUrl}
                  setUploadedImageUrl={setUploadedImageUrl}
                  transformedImageUrl={transformedImageUrl}
                  setTransformedImageUrl={setTransformedImageUrl}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  upscaledImageUrl={upscaledImageUrl}
                  setUpscaledImageUrl={setUpscaledImageUrl}
                  task={imageTask}
                  setTask={setImageTask}
                />
              </Tab>
              <Tab label="Transcribe Audio">
                <TranscribeAudioTab
                  task={audioTask}
                  setTask={setAudioTask}
                  language={audioLanguage}
                  setLanguage={setAudioLanguage}
                  file={audioFile}
                  setFile={setAudioFile}
                  audioUrl={audioUrl}
                  setAudioUrl={setAudioUrl}
                  transcriptionResult={transcriptionResult}
                  setTranscriptionResult={setTranscriptionResult}
                  transcriptionResponse={transcriptionResponse}
                  setTranscriptionResponse={setTranscriptionResponse}
                  isLoading={isAudioLoading}
                  setIsLoading={setIsAudioLoading}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </Box>
    );
  } else {
    // Render only the LoginForm if not authenticated
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LoginForm />
      </Box>
    );
  }
};

export default HomePage;
