import apiClient from "./apiClient"; // Adjust the import path based on your project structure

/**
 * Make an API call and update the application state based on the response.
 * @param {string} endpoint - The API endpoint to call.
 * @param {object} requestBody - The request body for the API call.
 * @param {string} taskType - The type of task being performed.
 * @param {function} setBrainstormHistory - Function to update brainstorm history state.
 * @param {function} setChatHistory - Function to update chat history state.
 * @param {function} setNonChatDraftHistory - Function to update non-chat draft history state.
 * @param {function} setIsNewChatSession - Function to update new chat session state.
 * @param {function} setGeneratedText - Function to update generated text state.
 * @param {function} setTokenInfo - Function to update token count information.
 * @param {boolean} isNewChatSession - State to indicate if it's a new chat session.
 * @param {string} prompt - The current prompt value.
 * @param {string} brainstormHistory - The current brainstorm history.
 * @param {string} chatHistory - The current chat history.
 */
export const makeApiCall = async (
  endpoint,
  requestBody,
  taskType,
  setBrainstormHistory,
  setChatHistory,
  setNonChatDraftHistory,
  setIsNewChatSession,
  setGeneratedText,
  setTokenInfo,
  isNewChatSession,
  promptHistory,
  brainstormHistory,
  chatHistory,
  setSessionData,
  updateTokenData,
  exchangeRate, // Add exchangeRate to the function parameters
  rateTimestamp
) => {
  try {
    // Endpoint map to direct API calls based on task_choice
    const endpointMap = {
      Freeform: "/generate/",
      Chat: "/chat/",
      Brainstorm: "/brainstorm/",
      "Summary": "/summarize/",
      Translation: "/translate/",
      "Press Release": "/generate/",
      "Press Statement": "/generate/",
      "Makabayan Press Statement": "/generate/",
      "House Bill": "/generate/",
      "House Resolution": "/generate/",
      "Explanatory Note": "/generate/",
    };
    // Determine the appropriate endpoint based on task_choice
    const endpoint = endpointMap[requestBody.task_choice] || "/generate/"; // Default to /generate/ if task_choice is not mapped
    console.log("Endpoint for task:", endpoint);

    // Use POST request with JSON body
    const response = await apiClient.post(endpoint, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = response.data;

    // Process response as before
    let newEntry = `You:\n${requestBody.prompt}\n\nMakGPT:\n${data.response}`;

    if (taskType === "Brainstorm" || taskType === "Chat") {
      const taskLabel = isNewChatSession ? `[${taskType}]\n\n` : "";
      newEntry = taskLabel + newEntry;

      if (taskType === "Brainstorm") {
        setBrainstormHistory(
          (prevHistory) =>
            `${prevHistory}${prevHistory ? "\n\n" : ""}${newEntry}`
        );
      } else {
        setChatHistory(
          (prevHistory) =>
            `${prevHistory}${prevHistory ? "\n\n" : ""}${newEntry}`
        );
      }
      setIsNewChatSession(false);
    } else {
      const taskLabel = `[${taskType}]\n`;
      newEntry = taskLabel + newEntry;

      let updatedHistory = newEntry;
      if (brainstormHistory) {
        updatedHistory = brainstormHistory + "\n\n" + updatedHistory;
      }
      if (chatHistory) {
        updatedHistory = chatHistory + "\n\n" + updatedHistory;
      }
      setNonChatDraftHistory(
        (prevHistory) =>
          prevHistory + (prevHistory ? "\n\n" : "") + updatedHistory
      );
      setIsNewChatSession(true);
    }

    setGeneratedText(data.response);
    setTokenInfo(data.token_count); // Assuming `data.token_count` is correctly populated
    console.log("API response:", data); 
    // Use updateTokenData here if your API response includes token info
    if (data.token_info) {
      // Combine the existing token_info with the taskType
      const updatedTokenInfo = {
        ...data.token_info,
        task: taskType // Add the taskType to the token_info
      };
      updateTokenData(updatedTokenInfo);
    }
    // Update session data based on the response
    setSessionData(data.session_data);
  } catch (error) {
    console.error("Error during API call:", error);
    // Handle error based on Axios error structure
    if (error.response) {
      // Server responded with a status code outside the 2xx range
      console.error("Server error response:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // An error occurred in setting up the request
      console.error("Error setting up request:", error.message);
    }
  }
};

export const fetchSummary = async (url, summaryStyle, sessionData, usdToPhpRate) => {
  try {
    const payload = {
      urls_string: url,
      summary_style: summaryStyle, 
      session_data: sessionData, // Include session data
      usd_to_php_rate: usdToPhpRate, // Include the exchange rate
    };

    const response = await apiClient.post("/summarize-urls/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const uploadPdf = async (file) => {
  const formData = new FormData();
  formData.append("uploaded_file", file);

  try {
    const response = await apiClient.post("/upload", formData);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const fetchAndUploadPdf = async (pdfUrl) => {
  try {
    const response = await apiClient.post("/fetch-pdf", { url: pdfUrl });
    return response.data;
  } catch (error) {
    console.error("Error fetching and uploading file:", error);
    throw error;
  }
};

export const summarizePdf = async (filePath, chunkSize, pageRange, sessionDataJson, usdToPhpRate) => {
  // Constructing the JSON payload
  const payload = {
    file_path: filePath,
    chunk_size: chunkSize,
    page_range: pageRange, // This can be undefined, and the backend should handle it appropriately.
    session_data: sessionDataJson, // Assuming this is already a JSON string.
    usd_to_php_rate: usdToPhpRate
  };

  try {
    const response = await apiClient.post("/summarize-pdf/", payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error in summarization:", error);
    throw error;
  }
};

export const summarizePdfDoc = async (filePath, pageRange) => {
  try {
    const response = await apiClient.post('/summarize-pdf-doc/', {
      file_path: filePath,
      page_range: pageRange
    });
    return response.data;
  } catch (error) {
    console.error('Error in summarizePdfDoc:', error);
    throw error;
  }
};

export const processQuery = async (data) => {
  try {
    const response = await apiClient.post("/process_query", data);
    return response.data;
  } catch (error) {
    console.error("Error processing query:", error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const response = await apiClient.post(
      "/user/logout",
      {},
      {
        // Ensure credentials are included with the request to handle cookies
        withCredentials: true,
      }
    );
    console.log(response.data.message); // "Logout successful"
    // Perform any additional actions on logout success, e.g., redirecting the user
  } catch (error) {
    console.error("Logout failed:", error);
    // Handle logout failure as needed
  }
};

export const generateImage = async (modelEndpoint, prompt, enhancePrompt, numImages, sessionData, usdToPhpRate) => {
  try {
    const response = await apiClient.post("/generate-image", {
      modelendpoint: modelEndpoint,
      prompt,
      enhance_prompt: enhancePrompt,
      num_images: numImages,
      session_data: sessionData,
      usd_to_php_rate: usdToPhpRate
    });
    return response.data; // Return the entire response
  } catch (error) {
    console.error("Error generating image:", error);
    throw error;
  }
};

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('uploaded_file', file);

    const response = await apiClient.post("/upload-image", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const transformImage = async (imageUrl, prompt, sessionData, usdToPhpRate) => {
  try {
    const response = await apiClient.post("/transform-image/", {
      prompt: prompt,
      image_url: imageUrl,
      session_data: sessionData,
      usd_to_php_rate: usdToPhpRate
    });

    return response.data;
  } catch (error) {
    console.error('Error transforming image:', error);
    throw error;
  }
};

export const uploadAudioFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await apiClient.post("/upload-audio/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading audio file:", error);
    throw error;
  }
};

export const transcribeAudio = async (audioUrl, task, language, sessionData, usd_to_php_rate) => {
  const startTime = performance.now();
  try {
    const response = await apiClient.post("/transcribe-audio/", {
      audio_url: audioUrl,
      task: task,
      language: language,
    });
    const endTime = performance.now();
    const processingTime = (endTime - startTime) / 1000; // Convert to seconds

    // Calculate cost
    const costUSD = processingTime * 0.00111; // $0.00111 per second
    const costPHP = costUSD * usd_to_php_rate;

    return {
      ...response.data,
      processingTime,
      costUSD,
      costPHP,
    };
  } catch (error) {
    console.error("Error transcribing audio:", error);
    throw error;
  }
};

export const createPowerPoint = async (content) => {
  try {
    const response = await apiClient.post('/create-pptx/', { content }, {
      responseType: 'blob',
    });

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'generated_presentation.pptx'; // Default filename
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (filenameMatch) {
        filename = decodeURIComponent(filenameMatch[1]);
        // Ensure the filename ends with .pptx
        if (!filename.toLowerCase().endsWith('.pptx')) {
          filename += '.pptx';
        }
      }
    }

    // Create a Blob from the response data
    const blob = new Blob([response.data], { 
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' 
    });
    
    // Create a link element, set the download attribute, and click it
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    return filename; // Return the filename if needed
  } catch (error) {
    console.error('Error creating PowerPoint:', error);
    throw error;
  }
};

export const upscaleImage = async (file, sessionData, exchangeRate) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('session_data', JSON.stringify(sessionData));
    formData.append('usd_to_php_rate', exchangeRate);

    const response = await apiClient.post('/upscale-image/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

/* Scrapes text content from provided URLs
* @param {string} urls - Newline-separated URLs to scrape
* @returns {Promise<{source_texts: string, error_messages: string[]}>}
*/
export const scrapeUrls = async (urls) => {
  try {
    const response = await apiClient.post('/scrape/', { urls });
    return response.data;
  } catch (error) {
    // Handle specific error cases
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      throw new Error(error.response.data.detail || 'Failed to scrape URLs');
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request
      throw new Error('Error setting up the request');
    }
  }
 };