import React from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// Function to return the slider value text
const valuetext = (value) => `${value}°C`;

const MySlider = ({
  defaultValue,
  step,
  min,
  max,
  onChange,
  label = "Default Label", // Default label text
  tooltipText = "Default Tooltip", // Default tooltip text
  valueTextFunction = valuetext, // Function to format the value text
  ...props
}) => {
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue); // Forward the value only
    }
  };

  return (
    <div>
      <Tooltip title={tooltipText}>
        <span>
          <div className="slider-container">
            <Typography id="slider-label">{label}</Typography>

            <Slider
              aria-labelledby="slider-label"
              defaultValue={defaultValue || 30}
              getAriaValueText={valueTextFunction}
              valueLabelDisplay="auto"
              size="small"
              step={step}
              marks={false}
              min={min}
              max={max}
              onChange={handleChange}
              sx={{
                "& .MuiSlider-thumb": {
                  color: "white", // Change thumb color
                },
                "& .MuiSlider-track": {
                  color: "white", // Change track color
                },
                "& .MuiSlider-rail": {
                  color: "grey", // Change rail color
                },
              }}
              {...props}
            />
          </div>
        </span>
      </Tooltip>
    </div>
  );
};

export default MySlider;
