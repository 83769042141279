import React, { useEffect } from "react";
import TextInput from "../components/TextInput"; // Adjust the path based on your project structure
import TextBox from "../components/TextBox";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AuthProvider"; // Import useAuth if org_type is stored in AuthProvider
import StyledButton from "../components/StyledButton";
import { createPowerPoint } from "../api/apiCalls";

const WriteTab = ({
  llmChoice,
  handleLlmChoiceChange,
  userSelectedLLM,
  handleTaskChoiceChange,
  taskChoice,
  languageChoice,
  handleLanguageChoiceChange,
  summaryStyleChoice,
  handleSummaryStyleChoiceChange,
  prompt,
  setPrompt,
  handleGenerateClick,
  handleClearClick,
  isGenerating,
  generatedText,
  setGeneratedText,
  scratchPadText,
  setScratchPadText,
  draftHistory,
  sourceText,
  setSourceText,
  handleFetchText,
  fetchError,
  isFetching,
  onFetchText,
  pressReleaseLanguage,
  setPressReleaseLanguage
}) => {
  const { user } = useAuth();

  const taskPlaceholderMapping = {
    Freeform:
      "For general writing tasks. Anything you want. Enter your instructions here. Specify genre/format (e.g., speech, essay, social media post, poem), topic, audience, length. Indicate key points and provide context (data, reference texts, etc.). Then click the WRITE button to generate text. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.",
    Chat: "For freewheeling discussion on any topic. Develop ideas and generate drafts through conversation.",
    Brainstorm:
      "Enter a topic and click the WRITE button to start a structured brainstorming session.",
    Summary:
      "Enter text to summarize or URLs (one per line). Choose a summary style and click Write to summarize. Text from URLs will be fetched automatically. Click Fetch to view text in URL text box below.",
    Translation:
      "Enter text, choose target language, click the WRITE button to translate.",
    Press_Release:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft press release for your organization. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.\n\nYou may enter a URL (followed by your instructions if needed) and click WRITE button. Text will be fetched automatically and draft generated.",
    Press_Statement:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft press statement for your organization. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.\n\nYou may enter a URL (followed by your instructions if needed) and click WRITE button. Text will be fetched automatically and draft generated.",
    House_Resolution:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft House Resolution for your organization. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.",
    House_Bill:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft House Bill for your organization. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.",
    Explanatory_Note:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft Explanatory Note for your organization.Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.",
    Makabayan_Press_Statement:
      "Enter instructions (e.g., topic, context, key points) then click the WRITE button to generate draft Makabayan press statement. Default output is in English. For output in Filipino, select Claude 3.5 Sonnet or GPT-4o model and give explicit instruction to write in Filipino.\n\nYou may enter a URL (followed by your instructions if needed) and click WRITE button. Text will be fetched automatically and draft generated.",
    "Q_&_A_Explainer":
      "Enter text (article, reference material, etc.) and click WRITE button to generate a Q & A Explainer.",
  };

  const promptPlaceholder =
    taskPlaceholderMapping[taskChoice.replace(/\s+/g, "_")] ||
    "Enter instructions here."; // Replace spaces with underscores for key matching

  useEffect(() => {
    // Since the first useEffect is currently empty, we can start by handling the default task logic
    if (!taskChoice) {
      const defaultTask =
        user?.org_type === "NDMO" || user?.org_type === "Partylist"
          ? "Press Release"
          : "Freeform";
      handleTaskChoiceChange({ target: { value: defaultTask } });
    }

    // Additional user interaction logic can be added here as needed
    // Be mindful of what needs to re-trigger this effect
  }, [
    user?.org_type,
    handleTaskChoiceChange,
    taskChoice,
    userSelectedLLM,
    llmChoice,
  ]);

  const taskOptions = {
    NDMO: [
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
      "Press Release",
      "Press Statement",
    ],
    Others: [
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
    ],
    Partylist: [
      // Provide the complete set of tasks for Partylist
      "Freeform",
      "Chat",
      "Brainstorm",
      "Summary",
      "Q & A Explainer",
      "Translation",
      "Press Release",
      "Press Statement",
      "House Resolution",
      "House Bill",
      "Explanatory Note",
      "Makabayan Press Statement",
    ],
  };

  // Determine which tasks to display based on org_type
  const displayedTasks = taskOptions[user?.org_type] || taskOptions["Others"]; // Fallback to "Others" if org_type is not defined

  const [isPptxGenerating, setIsPptxGenerating] = React.useState(false);

  const handlePressReleaseLanguageChange = (event) => {
    setPressReleaseLanguage(event.target.value);
  };

  const handleSaveAsPPTX = async () => {
    setIsPptxGenerating(true);
    try {
      const pptxBlob = await createPowerPoint(scratchPadText);

      // Create a link element, set the download attribute, and click it
      const fileURL = URL.createObjectURL(pptxBlob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "generated_presentation.pptx");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error("Error creating PowerPoint:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsPptxGenerating(false);
    }
  };

  const isUrlList = (text) => {
    const lines = text.split("\n");
    return lines.some((line) => {
      try {
        return new URL(line.trim());
      } catch {
        return false;
      }
    });
  };

  return (
    <Grid container spacing={2} className="content-container">
      <Grid item xs={12} className="main-content-header">
        <p>Generate drafts, brainstorm ideas, summarize and translate texts.</p>
      </Grid>
      <Grid item xs={12} container spacing={2} className="select-row">
        <Grid item>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="llm-choice-label" sx={{ color: "white" }}>
              Select LLM
            </InputLabel>
            <Tooltip
              title="Select LLM (Large Language Model) for generating texts. Defaults are fine for most use cases."
              arrow
              placement="top"
            >
              <Select
                style={{
                  backgroundColor: "#444",
                  color: "darkgrey",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                labelId="llm-choice-label"
                id="llm-choice"
                value={llmChoice}
                onChange={handleLlmChoiceChange}
              >
                {/* Default options rendered for all tasks and organizations */}
                <MenuItem value="Claude 3 Haiku">Claude 3 Haiku</MenuItem>
                <MenuItem value="Claude 3.5 Haiku">Claude 3.5 Haiku</MenuItem>
                <MenuItem value="Claude 3.5 Sonnet">Claude 3.5 Sonnet</MenuItem>
                <MenuItem value="GPT-3.5 Turbo">GPT-3.5 Turbo</MenuItem>
                <MenuItem value="GPT-4o (latest)">GPT-4o (latest)</MenuItem>
                <MenuItem value="GPT-4o">GPT-4o</MenuItem>
                <MenuItem value="GPT-4o mini">GPT-4o mini</MenuItem>
                <MenuItem value="Groq Llama-3 70B 8K">
                  Groq Llama-3 70B 8K
                </MenuItem>
                <MenuItem value="Groq Llama-3.1-70b-Versatile">
                  Groq Llama-3.1-70b-Versatile
                </MenuItem>

                {/* Conditional rendering based on organization type for GPT-4 Turbo */}
                {user?.org_type !== "NDMO" &&
                  user?.org_type !== "Partylist" && (
                    <MenuItem value="GPT-4 Turbo">GPT-4 Turbo</MenuItem>
                  )}
              </Select>
            </Tooltip>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="task-choice-label" sx={{ color: "white" }}>
              Select Task
            </InputLabel>
            <Select
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
              labelId="task-choice-label"
              id="task-choice"
              value={taskChoice}
              onChange={handleTaskChoiceChange}
            >
              {displayedTasks.map((task) => (
                <MenuItem key={task} value={task}>
                  {task}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {["Press Release", "Press Statement", "Makabayan Press Statement"].includes(taskChoice) && (
  <Grid item>
    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="press-release-language-label" sx={{ color: "white" }}>
        Select Language
      </InputLabel>
      <Select
        style={{
          backgroundColor: "#444",
          color: "darkgrey",
          fontSize: "14px",
          borderRadius: "4px",
        }}
        labelId="press-release-language-label"
        id="press-release-language"
        value={pressReleaseLanguage}
        onChange={handlePressReleaseLanguageChange}
      >
        <MenuItem value="English">English</MenuItem>
        <MenuItem value="English & Filipino">English & Filipino</MenuItem>
        <MenuItem value="Filipino">Filipino</MenuItem>
      </Select>
    </FormControl>
  </Grid>
)}
        {/* Conditionally render the Language Choice Select Component */}
        {taskChoice === "Translation" && (
          <Grid item>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="language-choice-label">Translate to</InputLabel>
              <Select
                style={{
                  backgroundColor: "#444",
                  color: "darkgrey",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                labelId="language-choice-label"
                id="language-choice"
                value={languageChoice}
                onChange={handleLanguageChoiceChange}
              >
                <MenuItem value="Tagalog">Tagalog</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Cebuano">Cebuano</MenuItem>
                <MenuItem value="Ilocano">Ilocano</MenuItem>
                <MenuItem value="Ilonggo">Ilonggo</MenuItem>
                <MenuItem value="Waray">Waray</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Conditionally render the Summary Select Component */}
        {taskChoice === "Summary" && (
          <Grid item>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="language-choice-label">Summary Style</InputLabel>
              <Select
                style={{
                  backgroundColor: "#444",
                  color: "darkgrey",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                labelId="summary-style-choice-label"
                id="summary-style-choice"
                value={summaryStyleChoice}
                onChange={handleSummaryStyleChoiceChange}
              >
                <MenuItem value="Bullets">Bullets</MenuItem>
                <MenuItem value="Powerpoint">Powerpoint</MenuItem>
                <MenuItem value="Notes & Insights">Notes & Insights</MenuItem>
                <MenuItem value="Narrative">Narrative</MenuItem>
                <MenuItem value="Q & A Explainer">Q & A Explainer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} xs={12} className="row-container">
        <Grid item md={6} xs={12}>
          <TextInput
            label="Your Prompt"
            user
            placeholder={promptPlaceholder}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            handleGenerateClick={handleGenerateClick}
            handleClearClick={handleClearClick}
            className="input1-container"
            isPromptBox={true}
            isGenerating={isGenerating}
            tabContext="WriteTab"
            showSaveIcon={false}
            customGenerateButton={
              <div
                className="button-container"
                style={{
                  display: "flex",
                  gap: "5px",
                  marginTop: "-0px",
                  marginRight: "0px",
                }}
              >
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateClick}
                  disabled={isGenerating}
                  isGenerating={isGenerating}
                >
                  {isGenerating && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="spinner"
                    />
                  )}
                  <span className="button-text">Write</span>
                </StyledButton>
                <Tooltip
                  title="Enter URLs (one per line) in 'Your Prompt' box and click to fetch text for display in 'Text from URLs' box below."
                  arrow
                  placement="top"
                >
                  <span>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={() => onFetchText(prompt)}
                    disabled={
                      isFetching || !prompt.trim() || !isUrlList(prompt)
                    }
                    isGenerating={isFetching}
                  >
                    {isFetching && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        className="spinner"
                      />
                    )}
                    <span className="button-text">Fetch</span>
                  </StyledButton>
                  </span>
                </Tooltip>
              </div>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput
            label="Your Draft"
            placeholder="Generated text will appear here."
            value={generatedText}
            onChange={(e) => setGeneratedText(e.target.value)}
            className="input1-container"
            tabContext="WriteTab"
            showSaveIcon={true}
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2} xs={12} className="row-container">
        <Grid item md={6} xs={12}>
          <TextInput
            label="Scratch Pad"
            placeholder="Collate and edit here. Save as text file or Powerpoint file. For Powerpoint, text should follow <heading + bullet points> per slide."
            value={scratchPadText}
            onChange={(e) => setScratchPadText(e.target.value)}
            className="input-container"
            tabContext="WriteTab"
            showSaveIcon={true}
            customButton={
              <Tooltip title="Save as PPTX file">
                <span>
                  {" "}
                  {/* Wrapper span to make the Tooltip work with a disabled button */}
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveAsPPTX}
                    disabled={isPptxGenerating || !scratchPadText.trim()}
                    isGenerating={isPptxGenerating}
                    style={{
                      marginLeft: "10px",
                      padding: "5px 10px",
                      fontSize: "0.8rem",
                    }}
                  >
                    {isPptxGenerating && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        className="spinner"
                      />
                    )}
                    <span className="button-text">PPTX</span>
                  </StyledButton>
                </span>
              </Tooltip>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput
            label="Output History"
            placeholder="Generated text for this session."
            value={draftHistory}
            readOnly={true}
            className="input-container"
            tabContext="WriteTab"
            showSaveIcon={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextBox
            label="Text from URLs"
            placeholder="Fetched text from URLs will appear here."
            value={sourceText}
            onChange={(e) => setSourceText(e.target.value)}
            className="input-container generated-text-container"
            tabContext="WriteTab"
            isBasicToolbar={true}
          />
        </Grid>
        {fetchError && (
          <div
            className="error-message"
            style={{ color: "red", marginTop: "8px" }}
          >
            {fetchError}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default WriteTab;
