import React, { useState } from "react";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
//import TextInput from "../components/TextInput";
import TranscriptionDisplay, {
  TimestampToggle,
} from "../components/TranscriptionDisplay";
import { UploadFile, Save } from "@mui/icons-material";
import { uploadAudioFile, transcribeAudio } from "../api/apiCalls";
import { useSessionData } from "../SessionDataProvider";
import { useExchangeRate } from "../ExchangeRateProvider";
import { useTokenTracker } from "../TokenTrackerContext";

const TranscribeAudioTab = ({
  task,
  setTask,
  language,
  setLanguage,
  file,
  setFile,
  audioUrl,
  setAudioUrl,
  transcriptionResult,
  setTranscriptionResult,
  transcriptionResponse,
  setTranscriptionResponse,
  isLoading,
  setIsLoading,
}) => {
  const { sessionData, setSessionData } = useSessionData();
  const { exchangeRate } = useExchangeRate();
  const { updateTokenData } = useTokenTracker();
  const [showTimestamps, setShowTimestamps] = useState(false);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
    // Reset language to null when switching to translate task
    if (event.target.value === "translate") {
      setLanguage(null);
    } else {
      setLanguage("tl"); // Set default language for transcribe task
    }
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setAudioUrl(null); // Reset the audio URL when a new file is selected
  };

  const handleTranscribe = async () => {
    if (!file && !audioUrl) {
      alert("Please upload an audio file first.");
      return;
    }

    setIsLoading(true);
    try {
      let transcriptionAudioUrl = audioUrl;

      if (!audioUrl) {
        const uploadResponse = await uploadAudioFile(file);
        transcriptionAudioUrl = uploadResponse.audio_url;
        setAudioUrl(transcriptionAudioUrl);
      }

      const transcriptionResponse = await transcribeAudio(
        transcriptionAudioUrl,
        task,
        task === "transcribe" ? language : undefined,
        sessionData,
        exchangeRate.rate
      );

      setTranscriptionResponse(transcriptionResponse);
      setTranscriptionResult(transcriptionResponse.full_text); // Add this line to maintain compatibility

      // Update session data and token tracker
      const updatedSessionData = {
        ...sessionData,
        total_cost_usd:
          sessionData.total_cost_usd + transcriptionResponse.costUSD,
        total_cost_php:
          sessionData.total_cost_php + transcriptionResponse.costPHP,
      };
      setSessionData(updatedSessionData);

      const audioOperation =
        task === "transcribe"
          ? `Transcribe - ${language === "tl" ? "Filipino" : "English"}`
          : "Translate";

      updateTokenData({
        audioCostUSD: transcriptionResponse.costUSD,
        audioCostPHP: transcriptionResponse.costPHP,
        modelName: "Whisper v3",
        audioOperation: audioOperation,
      });
    } catch (error) {
      console.error("Error in transcription process:", error);
      setTranscriptionResponse(
        "An error occurred during the transcription process. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    if (!transcriptionResponse && !transcriptionResult) {
      alert("No transcription result to save.");
      return;
    }

    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const fileName = file
      ? `${file.name
          .split(".")
          .slice(0, -1)
          .join(".")}_transcription_${timestamp}.txt`
      : `transcription_${timestamp}.txt`;

    // Get the formatted text based on current timestamp toggle state
    const textElement = document.querySelector(".transcript-container");
    if (!textElement) {
      alert("Cannot find transcript text to save.");
      return;
    }

    const textToSave = textElement.value;
    if (!textToSave) {
      alert("No text to save.");
      return;
    }

    const blob = new Blob([textToSave], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Grid container spacing={2} className="content-container">
      <Grid item xs={12} className="main-content-header">
        <p>Transcribe or translate audio files.</p>
      </Grid>

      <Grid item xs={12} container spacing={2} className="select-row">
        <Grid item>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="task-choice-label" sx={{ color: "white" }}>
              Select Task
            </InputLabel>
            <Select
              style={{
                backgroundColor: "#444",
                color: "darkgrey",
                fontSize: "14px",
                borderRadius: "4px",
              }}
              labelId="task-choice-label"
              id="task-choice"
              value={task}
              onChange={handleTaskChange}
            >
              <MenuItem value="transcribe">Transcribe</MenuItem>
              <MenuItem value="translate">Translate</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {task === "transcribe" && (
          <Grid item>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="language-choice-label" sx={{ color: "white" }}>
                Language
              </InputLabel>
              <Select
                style={{
                  backgroundColor: "#444",
                  color: "darkgrey",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                labelId="language-choice-label"
                id="language-choice"
                value={language}
                onChange={handleLanguageChange}
              >
                <MenuItem value="tl">Filipino</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ marginTop: "-60px", marginLeft: "10px", color: "grey"}}
        className="main-content-subheader"
      >
        <p>
          Upload audio file for transcription or translation (into English
          only).
        </p>
      </Grid>

      <Grid item xs={12}>
        <Box
          className="audio-upload-box"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            border: "2px dashed #666",
            borderRadius: "4px",
            marginTop: "-30px",
            marginLeft: { xs: "0px", sm: "10px" },
            width: { xs: "95%", sm: "91%" },
            maxWidth: "800px",
            p: 2,
            mb: 2,
            mx: "auto",
          }}
        >
          <Typography
            variant="body2"
            color="#999"
            sx={{
              mb: 2,
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            Supported formats: mp3, ogg, wav, m4a, aac. Max. file size 100MB.
          </Typography>
          <input
            accept="audio/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button
              sx={{ borderRadius: "8px", fontSize: "12px" }}
              variant="contained"
              component="span"
              startIcon={<UploadFile />}
            >
              Upload Audio File
            </Button>
          </label>
          <Typography variant="body2" color="#999" mt={1}>
            {file ? file.name : "No file selected"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleTranscribe}
            disabled={(!file && !audioUrl) || isLoading}
            sx={{
              borderRadius: "8px",
              fontSize: "12px",
              height: "36px",
            }}
          >
            {isLoading
              ? "Processing..."
              : task === "transcribe"
              ? "Transcribe"
              : "Translate"}
          </Button>
        </Box>

        {isLoading && (
          <Box sx={{ marginLeft: "10px", maxWidth: "800px", mt: 2 }}>
            <LinearProgress />
          </Box>
        )}
      </Grid>

      {(transcriptionResponse || transcriptionResult) && (
        <>
          <Grid item xs={12}>
            <TranscriptionDisplay
              transcriptionData={
                transcriptionResponse || { full_text: transcriptionResult }
              }
              showTimestamps={showTimestamps}
              onToggleTimestamps={(e) => setShowTimestamps(e.target.checked)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginLeft: "10px",
                marginTop: "-20px",
                marginBottom: "20px",
              }}
            >
              <TimestampToggle
                showTimestamps={showTimestamps}
                onToggleTimestamps={(e) => setShowTimestamps(e.target.checked)}
              />
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!transcriptionResponse && !transcriptionResult}
                startIcon={<Save />}
                sx={{
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                Save Transcript
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TranscribeAudioTab;
